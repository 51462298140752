* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-family: "Chakra Petch", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  --pink-color: #FF508F;
  --white-color: #fff;
  --grey-color: rgba(255, 255, 255, 0.7);
  --black-color:#000;
  --main-color:#FAE232;
  --main-color-dark:rgba(250, 226, 50, 0.8);
  --main-color-darker:rgba(250, 226, 50, 0.5);

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: var(--black-color);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

p, button, span, small, a, textarea {
  font-family: "Chakra Petch", sans-serif;
}

a {
  text-decoration: underline;
  color: var(--main-color);
  cursor: pointer;
}

.button {
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.button--contained {
  background-color: var(--main-color);
  color: var(--black-color);
}
.button--outlined {
  background-color: transparent;
  color: var(--main-color);
}

button:hover { 
  opacity: 0.8;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

body {
  margin: 0;
  max-width: 100vw;
  min-height: 100vh;
}

#navbar {
  position: relative;
  width: 100%;
  padding: 32px 40px 0 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

#navbar > img {
  width: 320.94px;
  height: 60px;
}

#navbar > button {
  width: 147px;
  height: 34px;
}

 .button-connected:focus-within ~ .disconnect-button {
  display: initial;
}

.disconnect-button {
  display: none;
  position: absolute;
  right: 40px;
  top: 85px;
  color: var(--pink-color);
  border-color: var(--pink-color);
}


#app {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  padding: 78px 162px;
}

.container {
  min-width: 423px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.tool-title {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}

.upload-container {
  padding: 54px 74px;
  margin: 60px 0;
  width: 423px;
  height: 204px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-color='white' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='42' stroke-linecap='square'/%3e%3c/svg%3e");
}

#uploadContainer > div {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
}

.upload-container--loaded {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  background: #191919;
  margin-top: 60px;
  width: 423px;
  height: 90px;
  padding: 15px 17px;
}

.transfer-button {
  width: 118px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 60px;
}

.upload-button{ 
  width: 275px;
  height: 80px;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  padding: 24px 40px;
}

.upload-container > .download-prompt {
  text-align: center;
  margin-top: 8px;
  color: var(--grey-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
}

.instruction-container {
  color:var(--grey-color);
}

.list {
  margin-left: 16px;
  margin-top: 12px;
  font-size: 14px;
  line-height: 18px;  
}

.output-textarea {
  width: 538px;
  flex-grow: 1;
  padding: 20px;
  margin: 10px 0;
  font-size: 14px;
  border: none;
  resize: none;
  background-color: #191919;
}

.file-card {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}


.lds-ring {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 1px solid var(--black-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--black-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 1150px) {
  #app {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 39px 81px;
  }

  .output-textarea {
    min-height: 15rem;
  }

  .instruction-container {
    margin-bottom: 4rem;
  }

}

@media only screen and (max-width: 768px) {
  #app {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    padding: 39px 81px;
  }
}



.progress {
  background: #343434;
  justify-content: flex-start;
  border-radius: 4px;
  align-items: center;
  position: relative;
  padding: 0 1px;
  display: flex;
  height: 4px;
  width: 100%;
}

.progress-value {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 40px -10px var(--main-color);
  border-radius: 100px;
  background: var(--main-color);
  height: 2px;
  width: 0;
}
